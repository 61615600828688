import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import Image from "next/image";
import "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import SlideAfterMotion from "@/components/common/animation/SlideAfterMotion";

const TestimonialsSection = ({ TestimonialData }) => {

  

  const slideMainItem = [];
  let id = 1;
  let count = 1;
  let sliderItem = [];

  TestimonialData?.map((item) => {
    sliderItem.push(item);
    count = count + 1;

    if (count == 4) {
      slideMainItem.push({
        id: id,
        slideItem: sliderItem,
      });
	  sliderItem=[]
      count = 1;
	  id=id+1
    }
  });



  return (
    <div className="testimonials_section_wrapper">
      <div className="container">
        <div className="top_arrow_slider">
          <SlideAfterMotion>
            <div className="slider_top_title_wrapper">
              <h5 className="slider_top_title">
                What Our <b className="color_title">Customers are Saying</b>{" "}
              </h5>
            </div>
          </SlideAfterMotion>

          <Swiper
            slidesPerView={1}
            slidesPerGroup={1}
            spaceBetween={5}
            loop={true}
            speed={1000}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
          >
            {slideMainItem &&
              slideMainItem?.map((item) => (
                <SwiperSlide key={item.id}>
                  {item?.slideItem &&
                    item?.slideItem.map((_item, index) => (
                      <div key={index} className="slider_item_card_wrapper">
                        <div className="slide_image_wrapper">
                          <Image
                            src={_item.image}
                            alt="Picture"
                            fill
                            sizes="100vw"
                            style={{
                              objectFit: "cover",
                              objectPosition: "top center",
                            }}
                          />
                        </div>
                        <SlideAfterMotion>
                          <div className="slide_author_info">
                            <h5 className="author_name">{_item.name}</h5>
                            <p className="author_address">{_item.address}</p>
                            <p className="author_comments">{_item.comments}</p>
                          </div>
                        </SlideAfterMotion>
                      </div>
                    ))}
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;
